@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Public+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
html {
  scroll-behavior: smooth;
  font-family: "Inter", sans-serif !important;
  line-height: 1.5;
  color: #ffffff;
}

* {
  /* font-family: "Montserrat", sans-serif; */
  /* font-family: "Public Sans", sans-serif; */

  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  /* line-height: 1.5; */
}

textarea::placeholder {
  font-weight: 300 !important;
  color: #a6abb7;
}

a {
  text-decoration: none;
  color: inherit;
}
button {
  text-transform: none !important;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container .content {
  width: 100%;
  max-width: 1250px;
  padding-inline: 16px;

  height: 100%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: flex-start;
}

.fullHeight {
  min-height: max(calc(100vh - 50px), 700px);
}

.max1Lines {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important; /* number of lines to show */
  line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
}
.max2Lines {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important; /* number of lines to show */
  line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}
.max3Lines {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important; /* number of lines to show */
  line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
}
.max4Lines {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 4 !important; /* number of lines to show */
  line-clamp: 4 !important;
  -webkit-box-orient: vertical !important;
}
.max5Lines {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 5 !important; /* number of lines to show */
  line-clamp: 5 !important;
  -webkit-box-orient: vertical !important;
}

/* Styling scroll bar */
*::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #353b44;
}

*::-webkit-scrollbar-thumb {
  border-radius: 0.5em;
  background-color: #70809080;
  /* outline: 1px solid slategrey; */
}
/* width */
.smallScrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.smallScrollbar::-webkit-scrollbar-track {
  background-color: #e0e0e0;
}
.smallScrollbar::-webkit-scrollbar-thumb {
  background: #000;
}
.smallScrollbar::-webkit-scrollbar-thumb:hover {
  background: #023c29;
  cursor: pointer;
}

.hideScrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.Select {
  z-index: 9999;
}

* img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}

.dokanGGlinks {
  font-weight: 600;
  color: #1432a0;
}

.policyTextBold {
  font-weight: 550;
}

/* wordBreak: "break-all" */
/* 
 */
/* 
  */
/* 
   */

/* Square Category Item End*/
.rdrDefinedRangesWrapper {
  display: none;
}
input::-webkit-calendar-picker-indicator {
  margin-right: 10px;
}

.fieldsetContainer fieldset {
  border-radius: 0px !important;

  border-color: #e4e5e7 !important;

  /* border-width: 1px !important; */
  transition: all 0.2s ease-in-out;
}

.fieldsetContainer > div > div > div > div {
  transition: all 0.2s ease-in-out;
}

.fieldsetContainer:hover > div > div > div > div {
  background-color: #f2f2f4 !important;
  border-color: #e4e5e7 !important;
}
